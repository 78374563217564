.card-style {
  width: 30vw;
}

@media screen and (max-width: 1400px) {
  .card-style {
    width: 40vw;
  }
}

@media screen and (max-width: 600px) {
  .card-style {
    width: 300px;
  }
}
